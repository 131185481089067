<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module/exam-management" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.examManagement.title") }}
        </router-link>
        <router-link to="/module/exam-management/question/category" class="btn btn-primary align-self-center ms-3">
            {{ $t("pages.module.examManagement.question.category.title") }}
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr024.svg" />
            </span>
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.examManagement.question.title')"
        :subTitle="$t('pages.module.examManagement.question.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleExamQuestionFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:type="{ row: record }">
            {{ resolveDatum(types, record.type_id, 'id').name }}
        </template>
        <template v-slot:correctAnswer="{ row: record }">
            <a v-if="record.type_id == 1 && record.correct_answer_id && record.answers.length" class="fw-bold text-gray-600 text-hover-primary cursor-pointer" v-on:click="showDetail('correctAnswerModal', record)">
                {{ sprintf($t('pages.module.examManagement.question.answer.titlePattern'), [$root.questionLetters[getCorrectAnswer(record).foundIndex]]) }}
            </a>
            <span v-else>{{ getRecord('correctAnswer', record) ?? "-" }}</span>
        </template>
        <template v-slot:answerCount="{ row: record }">
            {{ getRecord('answerCount', record) ?? "-" }}
        </template>
        <template v-slot:content="{ row: record }">
            <div v-html="resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').content" v-math></div>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.examManagement.question.answer.title')" placement="top" v-if="record.answers && record.answers.length">
                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 cursor-pointer" v-on:click="showDetail('answerModal', record)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <router-link :to="'/module/exam-management/question/save/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                     </span>
                </router-link>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_correct_detail" ref="correctAnswerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_correct_detail_header">
                    <h2 class="fw-bolder">{{ $t("pages.module.examManagement.question.cols.correctAnswer") }}</h2>
                    <div id="kt_modal_correct_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_correct_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <template v-if="showDetailData && showDetailData.record">
                            <div class="fv-row detail-item" v-html="resolveDatum(showDetailData.record.translations, $root.defaultLanguage.id, 'language_id').content" v-math></div>
                        </template>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.correctAnswerModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_answer_detail" ref="answerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_answer_detail_header">
                    <h2 class="fw-bolder">{{ $t("pages.module.examManagement.question.answer.title") }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <template v-if="showDetailData">
                            <div class="fv-row detail-item" v-for="(answer, answerIndex) in showDetailData.answers" :key="answerIndex" :class="[answerIndex != 0 && 'mt-3']">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ sprintf($t('pages.module.examManagement.question.answer.titlePattern'), [$root.questionLetters[answerIndex]]) }}:</label>
                                <span class="text-gray-500 fw-bold fs-6" v-html="resolveDatum(answer.translations, $root.defaultLanguage.id, 'language_id').content" v-math></span>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.answerModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.question.cols.content"),
                    scopedSlots: {customRender: "content"}
                },
                {
                    name: this.$t("pages.module.examManagement.question.cols.type"),
                    scopedSlots: {customRender: "type"}
                },
                {
                    name: this.$t("pages.module.examManagement.question.cols.correctAnswer"),
                    scopedSlots: {customRender: "correctAnswer"}
                },
                {
                    name: this.$t("pages.module.examManagement.question.cols.answerCount"),
                    scopedSlots: {customRender: "answerCount"},
                    class: ""
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: [],
            showDetailData: null,
        }
    },
    computed: {
        table() {
            return this.$store.state.module.exam.question.table;
        },
        types() {
            return this.$store.state.module.exam.question.type.table.data;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.examManagement.question.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.examManagement.title")]);

        this.$store.dispatch('module/exam/question/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                include_answers: 1
            })
        });

        this.$store.dispatch('module/exam/question/type/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        deleteRecord(id){
            this.$store.dispatch("module/exam/question/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/module/exam-management/question/save"
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/exam/question/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/exam/question/get", {
                page: pagination,
                filterData: Object.assign({
                    include_answers: 1
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        getCorrectAnswer(record) {
            let result = null;

            if(record.answers && record.answers.length && record.correct_answer_id){
                record.answers.sort((a,b) => {
                    return a.sort - b.sort;
                });

                let foundIndex = record.answers.findIndex((answer) => answer.id == record.correct_answer_id);

                if(foundIndex != -1){
                    result = {
                        foundIndex: foundIndex,
                        record: record.answers[foundIndex],
                    }
                }
            }

            return result;
        },
        showDetail(modalName, record) {
            this.showDetailData = record;

            if(modalName == 'correctAnswerModal'){
                this.showDetailData = this.getCorrectAnswer(record);
            }

            let modal = this.$refs[modalName];
            if(modal && this.showDetailData){
                this.showModal(modal);
            }
        },
        getRecord(type, record){
            if(this.types.length) {
                let recordTypeCode = this.resolveDatum(this.types, record.type_id, 'id').code;
                let splitted = recordTypeCode.split('_').map((part) => this.ucFirst(part));

                let methodName = 'getRecord' + splitted.join('');

                return this[methodName] ? this[methodName](type, record) : false
            }
        },
        getRecordNormal(type, record){
            let typeData = {
                answerCount: record.answers_count
            }

            return typeData[type] ?? null;
        },
        getRecordImage(type, record){
            let typeData = {
                answerCount: record.external_answer_count,
                correctAnswer: this.sprintf(
                    this.$t('pages.module.examManagement.question.answer.titlePattern'),
                    [this.$root.questionLetters[(record.correct_answer_id - 1)]]
                )
            }

            return typeData[type] ?? null;
        },
        getRecordTrueOrFalse(type, record){
            let typeData = {
                answerCount: 2,
                correctAnswer: this.$t('common.' + (record.correct_answer_id == 1 ? 'true' : 'false'))
            }

            return typeData[type] ?? null;
        }
    }
}
</script>

<style>
.table p {
    margin-bottom: 0;
}

.table img, .modal .detail-item img {
    width: 180px !important;
    height: 90px !important;
    object-fit: cover !important;
}
</style>